@import "../../css/utility.scss";
.p-header {
  @include project-header;
  padding: 0px 25px 5px 25px;
  display: flex;
  // @media screen and (min-width: 1600px) {
  //  padding: 15px 25px;
  // }
}

.project-title {
  color: var(--theme-color);
  font-weight: var(--font-bolder) !important;
  font-size: 16px !important;
  display: flex;
  @media screen and (min-width: 1600px) {
    font-size: 20px !important;
    //  padding: 15px 25px;
  }
}
.administartor-project-title {
  font-weight: var(--font-bolder) !important;
  font-size: 16px !important;
  align-items: center;
  display: flex;
  min-height: 50px;
  width: 100%;
  padding-left: 23px;
  .backIcon {
    font-style: normal;
    font-weight: var(--font-bolder);
    font-size: 40px;
    color: var(--color-black);
  }
  @media screen and (min-width: 1600px) {
    padding: 15px 25px;
  }
}

.admin-box {
  border-top: 1px solid var(--borderLightGrey);
  border-bottom: 1px solid var(--borderLightGrey);
  min-height: 54.5px;
}
