@import "../../../css/utility.scss";

.BasicDatePiker-wrap {
  align-items: center;

@include BasicDatePiker-wrap;
  .date-picker {
    border: transparent;
    padding: 7px 0 7px 0;
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    .react-datepicker__input-container {
      input {
        text-align: center;
      }
    }
  }

  .admin-search {
    &.MuiGrid-item {
      padding-left: 15px !important;
    }
  }

  .btn-search {
    @include button-bg-blue;
    padding: 6.76px 30px;
    margin: 0px;
    box-shadow: none;
  }

  .status-search {
    min-width: 120px;
    background-color: var(--bg-white);
    margin: 0 15px;
    border-radius: var(--border-radius10);
  }

  .box {
    border: 1px solid #c5c0c0;
    display: flex;
    border-radius: var(--border-radius6);
    padding: 3.54px 5.5px;
    // background-color: var(--bg-white);
    align-items: center;

    .datePikerTo {
      color:var(--color-black);
      padding: 0px 10px;
      font-size: 14px;
      font-weight: var(--font-normal);
    }
  }
}

@media screen and (min-width: 1600px) {
  .box {
    border-radius:var(--border-radius10);
    padding: 5.5px;
  }

  .btn-search {
    padding: 8.76px 30px;
  }
}

// .react-datepicker-wrapper {
//   border: 1px solid #B9B9B9 !important;
//   border-radius: 6px;
//   text-align: center;
//   .react-datepicker__input-container {
//     .DateRangePickerAdministrator {
//       border: 0;

//     }

//     input {
//       text-align: center;
//       height: 40px;
//       width: calc(100% - 30px);

//     }
//   }
// }