@import "../../../css/utility.scss";
.imageview-header {
  display: flex;
  justify-content: space-between;
  .angle-arrow-grid {
    display: flex;
    padding: 5px 0px;
    justify-content: space-evenly;
    .angle-arrow-display {
      display: flex;
      justify-content: center;
      align-items: center;
      .arrow-angle-condition{
        // padding: 4px;
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none !important;
      }
      .angle-arrow-left {
        height: 20px;
        color: #e72626;
      }
      .angle-arrow-right {
        height: 20px;
        color: #ececec;
      }
    }
    .angle-arrow-text{
        margin: 0;
        font-size: 10px;
        color: #202020;
        font-family: var(--font-family);
    }
  }
}
.imageView-progressbar-div {
  display: flex;
  justify-content: center;
  padding-top: 20;
  height: 768;
  .circular-loader-style {
    padding-left: 15px;
    padding-top: 10;
  }
}
