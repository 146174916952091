@import "../../../css/utility.scss";

.upload-template-wrap {
  margin-top: 2%;
  justify-content: center;
  display: flex;

  h6 {
    font-size: 14px;
    margin: 0 0 10px;

    @media screen and (max-width: 1330px) {
      min-height: 42px;
    }
  }
  .MuiOutlinedInput-input {
    padding: 8.3px 32px 8.3px 15px !important;
  }

  .upload-template-info {
    margin: 0 5px;
  }

  .upload-select-dropdown {
    width: 500px;
  }
  .template-name-style{
    margin: -7px;
    .close-icon-style{
      color: #525863
    }
  }

  @media screen and (min-width: 1600px) {
    h6 {
      font-size: 16px;
    }
  }
}
