@import "../css/utility.scss";
.main-pageNotFound-container {
  height: 90%;
  align-items: center;
  .pageNotFound-data {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    .pageNotFound-card {
      flex: 1 0 auto;
      .card-info-wrap {
        margin-left: 8px;
      }
      .go-back-btn {
        padding-top: 20px;
        margin: 0 !important;
      }
    }
  }
  .pageNotFound-icon {
    img {
      margin-left: 11px;
    }
  }
}
// Table Skeleton
.tableSkeleton-main {
  overflow: hidden;
}
.tableSkeleton-wrap {
  width: 100%;
  margin-right: 42px 4px 42px 0;
  height: 800px;
}
// Timeout Modal Scss
.timeOut-dialog-text {
  display: flex;
  justify-content: space-between;
}
