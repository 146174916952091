@import "./../../../css/utility.scss";

.forgotPassword-container-wrap {
  width: 100%;
  height: 100vh;
  background: var(--color-gray);
  .forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7% 8%;
    height: 100vh;
    .logoWrap {
      margin: 0 auto;
      img {
        margin: 0 auto;
        width: "100%";
        height: "auto";
      }
    }
    h5 {
      font-size: 30px;
      text-align: center;
      margin: 20px 0;
    }
    input {
      padding: 8px;
      width: 100%;
      border-radius: var(--border-radius10);
    }
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
      border-radius: var(--border-radius10);
      background: none;
      border: 1px solid #828282 !important;
    }
    .input-wrap {
      width: 100%;
      height: 42px;
      margin-bottom: 1rem;
    }

    label {
      font-size: 16px;
      margin-left: 15px;
      // color: #828282;
      margin-bottom: 5px;
    }
    .forgetPassword {
      text-align: right;
      font-weight: var(--font-bolder);
      font-size: 16px;
      // margin-bottom: 1.5rem;
      button { 
        font-size: 16px;
      }
    }
    .btn-continue {
      font-weight: var(--font-bolder);
      font-size: 18px;
      text-transform: none;
      margin-bottom: 1rem;
      border-radius: var(--border-radius10);
      @include button-bg-blue;
    }
    .ac-processor-login-user-not-found-error-message {
      display: flex;
      margin-bottom: 5px;
    }
  }
}
.logInImg {
  h5 {
    font-size: 20px;
    text-align: center;
    margin: 15px;
  }
}
.forgot-pass-form {
  input {
    height: 42px;
    padding: 0 15px !important;
  }
  .forgot-continue {
    background-color: #0037b2 !important;
    height: 42px;
    font-weight: var(--font-bolder);
    font-size: 16px;
    text-transform: capitalize;
  }
  .forgot-disabled {
    &.MuiButtonBase-root {
      background-color: var(--bg-gray) !important;
      color: white !important;
      text-transform: capitalize;

      // margin-top: 40px;
    }
    // button {
    height: 42px;
    font-weight: var(--font-bolder);
    font-size: 16px;
    text-transform: capitalize;
    background-color:var(--bg-gray) !important;
    // }
  }
}

.error_messge {
  display: flex;
  flex-direction: row;
  // .MuiFormHelperText-root {
  //   margin-left: 8px !important;
  //   padding-bottom: 10px;
  //   color: #fd3458 !important;
  // }
  .error {
    label {
      color: var(--color-error);
      font-size: 14px;
      margin: 0 0 5px;
      display: inline-block;
      padding-left: 10px;
    }
  }
}
// newly added code

.forgotpass-wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .forgotpass-form-wrap {
    background-color: var(--bg-white);
    padding: 32px 70px 47px;
    border-radius: 20px;
    box-shadow: 0 0 7px #6c6c6c;
    .loginFormWrap {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid var(--borderLightGrey);
      padding: 15px 0px;
      img {
        width: 200px;
        margin: 0 auto;
      }
    }
  }
}
