@import "../../../css/utility.scss";
.bottom-btn-wrap{
  display: flex;
  padding-right: 10px;
  border-top:1px solid #B9B9B9 ;
  width: 100vh;
}

.bottom-btn {
  @include button-bg-blue;
  margin: "10px" !important;
  border-radius: var(--border-radius6);
}

.bottom-btn-selected {
  background-color: rgb(255, 255, 255) !important;
  color: var(--theme-color) !important;
  border: 2px solid var(--theme-color) !important;
  margin: "10px";
  border-radius: var(--border-radius6);
}
