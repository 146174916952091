@import "../../css/utility.scss";
.MapTableFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 8px;
  text-align-last: end;
  @include Footer;
  .MuiButton-startIcon {
    margin: 0 !important;
  }
  .MapTbleFbuttons {
    display: flex;
    justify-content: end;
    .MuiButton-startIcon {
      margin: 0px !important;
      width: 15px;
    }
  }
  .btn-contained {
    @include button-bg-blue;
  }
  button {
    font-size: 16px;
    text-transform: capitalize;
    padding: 6.79px 5px;
    height: 38px;
    line-height: 14px;
    margin: 0 8px !important;
    min-width: 112px;
    @include outline-blue-button;
    .startIcon {
      margin: 0 !important;
    }
    &:last-child {
      margin-right: 0;
    }
    &.Mui-disabled {
      border: 2px solid var(--borderLightGrey);
    }
    // &.btn-contained.Mui-disabled {
    //   color:var(--colorTextWhite);
    //   background-color:#b9b9b9 !important;
    // }
  }
  .page-no {
    background: var(--bg-Table-Disable);
    border-radius: var(--border-radius4);
    padding: 0px 3px;
    height: 21px;
    color:var(--color-black);
    font-weight: var(--font-bold);
  }
  .StackPage {
    margin: 10px -1px;
  }
} 
