@import "../../../css/utility.scss";
.Sidebar-add {
  // width: 100%;
  // height: 690px;
  // left: 0px;
  // top: 70px;
  // background-color: var(--theme-color);
}

.line {
  width: 100%;
  height: 47px;
  border-bottom: 1px solid rgb(228, 213, 213);
  position: absolute;
  bottom: 60px;
}

.center-div {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    border-radius: 4px !important;
  }
  input:required:valid {
    border: 1px solid rgb(250, 247, 247) !important;
  }
}

.bottom-div {
  position: fixed;
  border-top: 1px solid var(--borderLightGrey);
  bottom: 0px;
  width: calc(100% - 25%);
  text-align: right;
  padding-right: 10px;
  height: 70px;
  button {
    padding: 5px 80px;
  }
}
.popup {
  position: fixed;
  top: 20px;
  right: 30px;
}
.side-bar-wrap{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #F5F5F5;
  height: 60px;
  border-bottom: 1px solid #B9B9B9;
  border-top: 1px solid #B9B9B9;
  .backButton{
    margin-left: 16px!important;
    font-weight: var(--font-bold); 
    font-size: 16px;
    font-style: normal;
  }
}
.side-bar{
  margin-left: 2px;
  font-style: normal;
  font-weight: var(--font-bold);
}
.addGrouip-wrap{
  width: 100%;
  height: 62vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
}
.addGroup-massage{
  margin-left: 16px;
  font-style: normal;
  font-weight: var(--font-bold);
  font-size: 16px; 
}