@import "../../../css/utility.scss";
.uploadbatch-main-container {
  padding: 0 40px;
  .uploadpdf-text {
    margin: 16px;
    margin-bottom: 8px;
  }
  .uploadpdf-form {
    margin: 8px;
    width: 300px;
    .uploadpdf-label {
      margin: -7px;
      padding-left: 10px;
    }
    .uploadpdf-select {
      @include button-bg-blue;
      border: 1px solid var(--theme-color);
    }
  }
}
.gridBox {
  padding: "20px";
}

.tag-name-error {
  padding-left: 50px;
  color: #fd3458;
}

.pdf-card-container {
  padding-left: 49px;
  padding-right: 49px;
  height: calc(100vh - 300px);
  overflow-y: scroll;
}
