@import "../../../css/utility.scss";
.batchProcessing {
  padding: 40px;
  .blueLabel {
    color: var(--main-theme-color);
    font-weight: var(--font-bolder); 
  }
  .h-label2 {
    font-weight: var(--font-normal);
  }
  .input-wrap {
    margin-bottom: 1rem;
    .input-wrap-text {
      border: 1px solid var(--borderLightGrey);
      border-radius: var(--border-radius10);
    }
    .input-wrap-select {
      border: 1px solid var(--borderLightGrey);
      border-radius: var(--border-radius10);
    }
  }
  .batch-processing-form {
    @include batch-processing-form;
    label {
      font-size: 16px;
      font-weight: var(--font-bold);
      margin-bottom: 10px;
      display: inline-block;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 8px 14px !important;
    }
  }
  .Date-picker {
    padding: 8px 14px !important;
    width: 100%;

    height: 62px;

    font-family: var(--font-family);
    /* Base Color/Primery */

    background: var(--bg-white);
    /* Base Color/Lines */

    border: 1px solid var(--borderLightGrey);
    border-radius: var(--border-radius10);
  }
}
.BatchInfoBorder {
  border: 1px solid var(--borderLightGrey);
  border-radius: var(--border-radius10);
}
