.new-batch-wrap {
  width: 100%;
  margin-top: 4%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  .MuiOutlinedInput-input {
    padding: 7.5px 12px;
  }
  .new-batch-info {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h6 {
      font-size: 14px;
      margin: 0 0 10px;
    }
  }
}
