@import "../../../css/utility.scss";
.json-modal {
  .MuiBackdrop-root {
    background-color: transparent !important;
  }
}
.__json-pretty__ {
  overflow: visible !important;
  margin: 0 !important;
}
.jsonViewerModal{
  position: absolute;
  top: 50%;
  left: 76%;
  height: 74%;
  transform: translate(-50%, -50%);
  width:29%;
  border-radius: var(--border-radius4);
  border: 1px solid #B9B9B9;
  background: black;
  overflow: scroll;
}