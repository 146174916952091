@import "../../css/utility.scss";
.outputTable-container{
  overflow-y: scroll;
  height: calc(100vh - 180px);
}
.table-output {
  border: 0;
  margin: 0;
  border-collapse: separate !important;
  border-spacing:1px 8px !important;
  outline: 1px solid black;
  th {
    border: 0;
    border-collapse: separate !important;
    border-spacing: 8px;
    font-weight: var(--font-bold);
    min-width: 90px;
    &.MuiTableCell-root{
      padding:12px !important;
    }
  }
  td {
    border: 0;
    border-collapse: separate;
    border-spacing: 8px !important;
    padding: 5px !important;
    padding-left: 18px !important; 
    font-weight: var(--font-normal);
  }
  thead {
    tr {
      outline: 1px solid var(--borderLightGrey);
      text-transform: capitalize;
    }
  }
  tr {
    margin: 10px 0px;
    outline: 1px solid var(--bordertableData);
    border-radius:var(--border-radius4);
  }
}
.action-list {
  .MuiPopover-paper {
    box-shadow: none !important;
  }
  .MuiMenu-list {
    background-color: var(--bg-Table-Disable);
    padding: 0;

    .action-item {
      color:var(--color-black);
      font-size: 12px !important;
      padding: 8px 14px;
      border-bottom: 1px solid var(--borderLightGrey);
      svg {
        margin-right: 8px;
      }
      &:hover {
        color: var(--colorTextWhite);
        background-color:var(--theme-color);
      }
    }
  }
}
.css-rqglhn-MuiTable-root {
  border-collapse: separate !important;
  border-spacing: 8px !important;
}
