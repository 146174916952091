@import "../../../css/utility.scss";

.info-modal {
  .MuiBackdrop-root {
    background-color: transparent !important;
  }
}

.__json-pretty__ {
  overflow: visible !important;
  margin: 0 !important;
}

.infoModal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  width: auto;
  border-radius: var(--border-radius0) 4px solid !important;
  border: 1px solid #fff !important;
  // background: black;
  //overflow: scroll;
  padding: 6px 15px;
  border-radius: 8px !important;


  .__json-pretty__ {
    line-height: 13;
    color: var(--body-text-color) !important;
    background: var(--color-body) !important;
    overflow: auto;
  }


  .__json-key__ {
    color: var(--body-text-color) !important;
  }


  .__json-value__ {
    color: var(--body-text-color) !important;
  }


  .__json-string__ {
    color: var(--body-text-color) !important;
  }


  .__json-boolean__ {
    color: var(--body-text-color) !important;
  }


  .__json-pretty-error__ {
    line-height: 1.3;
    color: var(--secondary-body-color) !important;
    background: #1e1e1e;
    overflow: auto;
  }
}