@import "../css/utility.scss";
.css-1rq8huz-MuiGrid-root,
.css-3weykx-MuiGrid-root,
.MuiGrid-item {
  font-size: 14px !important;
  padding: 0px;
}
.tableHeader {
  background-color: var(--bg-white);
  border: 1px solid var(--borderLightGrey);
  border-radius: var(--border-radius4);
}
.table-data {
  border: 1px solid var(--bordertableData) !important;
  border-radius: var(--border-radius4);
  margin: 5px 0 !important;
  .template-upload {
    color: #48b06b !important;
  }
  .processor_assigned {
    color: #48b06b !important;
  }
  .processor_not_assigned {
    color: #0067fb !important ;
  }
  .template-notfound {
    color: #fd3458 !important;
  }
}
// .css-mhc70k-MuiGrid-root > .MuiGrid-item {
//   padding-top: 0 !important;
// }
.hidden-row {
  padding: 5px;
  background: var(--bg-DayCard);
  align-items: center;
  width: 100% !important;
  border-bottom: 1px solid var(--borderLightGrey);
  margin: 0 !important;
  &:first-child {
    margin-top: -5px !important;
  }
  &:last-child {
    border-bottom: none;
  }
}
.text-center {
  text-align: center;
}
.break-word {
  word-break: break-all;
}
.moreVerIcon-wrap {
  padding-left: 16px;
}
.dataTable-wrap {
  background: var(--bg-administrator-table);
  height: calc(100vh - 100px);
}
.DataTable-wrap {
  padding: 3;
  background: var(--bg-administrator-table);
  border-radius: 0;
  .tableHeader {
    font-size: 14px;
    font-weight: var(--font-bold);
    color: "#202020";
    padding: 5px;
    align-items: center;
  }
} 
.moreVerIcon-wrap {
  .projectMenu {
    color: #3049f5;
    font-size: 12px;
  }
  .batchMenu {
    font-size: 10px;
    color: #3049f5;
  }
}
