@import "./../css/utility.scss";
.Admin-BatchInfo-wrap{
   background:var(--bg-administrator-table);
   height:calc(100vh - 130px); 
}
.table-row{
  border: 1px solid var(--borderLightGrey);
  border-radius: var(--border-radius4);
}
.Admin-BatchInfo {
  padding: 5px 20px;
  @include dashboard-wrap;
  border: none;
  .batchInfo-container{
    overflow-y: scroll;
    height: calc(100vh - 185px);
    box-shadow: none;
  }
  .dashBoardTh {
    font-size: 14px;
    font-weight: var(--font-bolder); 
    color: #202020;
    height: calc(100vh - 178px);
    th{
      background-color: var(--bg-white) !important;
    }
    table {
      border-spacing: 0 6px !important;
    }
    table tr td {
      padding: 6.5px 5px !important;
      height: 45px !important;
      // text-align: center;
      text-align: left!important;
    }
  }
}

.batch-info-table,
// .batch-info-table td,
.batch-info-table th {
  border-collapse: separate !important;
  border-spacing:1px 8px !important;
  border: 0px;
  font-weight: var(--font-bold)!important;
}
.batch-info-table tr {
  outline: 1px solid #ECECEC !important;
  border-radius: var(--border-radius4);
}