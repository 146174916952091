@import "../../../css/utility.scss";
.project-main-header{
  display: flex;
  padding: 1px;
  border-top: 1px solid #B9B9B9;
}
.header-btn {
  background-color: rgb(31, 200, 204) !important;
  margin: "10px";
  padding-right: "100px" !important;
}

.header-btn-selected {
  background-color: var(--bg-gray) !important;
  margin: "10px";
  padding-right: "100px" !important;
}
