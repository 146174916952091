@import "../../../css/utility.scss";
.signatureOutputContainer {
  height: 150px;
  width: 500px;
  border: 1px solid var(--borderLightGrey);

  background: var(--bg-white);
  border-radius: var(--border-radius10);
  padding: 2px;
  text-align: center;
  position: relative;
  .css-1ps6pg7-MuiPaper-root {
    box-shadow: none !important;
  }
}
.signatureCanvas {
  width: 494px;
  height: 150px;
  padding: 10px;
}
.esignImage {
  margin: 0 auto;
}
.esignUploadedText {
  position: absolute;
  text-align: right;
  right: 10px;
  bottom: 10px;
}
 .modal-sign-box{
  width: 564px !important;
  position: absolute;
  top: 12%;
  left: 30%;
  transform: translate(-50% -50%);
  width: 100%;
  @include modal;
  border-radius: var(--border-radius10);
  box-shadow: 24;
  padding: 32px;
  display: flex;
  flex-direction: column;
 }
  .e-sing-title{
    margin-bottom: 20px;
    font-weight: var(--font-bold);
    .MuiSvgIcon-root{
      float: right!important;
    }
  } 
  .user-name{
    font-size: 1rem;
    font-weight: var(--font-bold);
    margin-bottom: 8px;
  }
  .signatureContainer {
    height: 150px;
    width: 500px;
    border: 1px solid var(--borderLightGrey);
    border-radius: var(--border-radius10);
    margin-bottom: 18px;
    padding: 10px;
    position: relative;
  }
.signatureOutputContainer{
  .MuiPaper-root{
    height: 145px;
    box-shadow: none;
    background-color: none;
    .esignImage{
  height: 145px;
   width: 481px;
    }
  }
}
.e-sign-modsal-btn-wrap{
  display: flex;
  justify-content: right;
  .btn-clr{
    // border: 3px solid var(--theme-color);
    padding: 5px 30px;
    // color: var(--theme-color);
    margin: 15px 7px 10px 0px;
    @include outline-blue-button;
    &:hover{
      @include outline-blue-button;
    }
  }
  .btn-upload{
    padding: 5px 30px;
    margin: 15px 0px 10px 7px;
    @include button-bg-blue;
  }
}