.skaleton-table {
    padding:  10px 20px 20px 20px;
}

.skeleton-heading {
  height: 50px !important;
}
.skeleton-row {
  height: 40px !important;
}
