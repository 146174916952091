@import "../../../css/utility.scss";
// css for Reject Modal
.clone{
  display: flex;
  margin-top: 10px;
  margin-bottom: -5px;
.projectName-outline{
  margin-right: 16px!important;
}
}
.button-clone{
  @include button-bg-blue;
  padding: 2px 10px !important;
  margin-right: -15px;
}
.modal-box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  box-shadow: 24;
  padding: 32px;
  .cloneModal-close{
    position: absolute;
    right: 15px;
    top:15px;
  }
  #modal-modal-title{
    margin-bottom: 16px;
    margin-top: -16px;
  }
}