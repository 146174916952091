@import "../../css/utility.scss";
.AddRow-wrap {
  h6 {
    margin: 0;
    font-size: 14px;
    color: #202020;
    font-weight: var(--font-normal);
  } 
  .rightClick-icon {
    @include button-black;
    border-radius:var(--border-radius6);
    margin-left: 6px !important;
    padding: 7.5px !important;
  }
  .addRow-inputField {
    display: flex;
    justify-content: center;
    width: 180px;
    .add-coloumnsInput {
      text-align: center !important;
    }
  }
  .MuiOutlinedInput-input {
    padding: 6.5px 21px !important;
  }
  .MuiOutlinedInput-root {
    padding-left: 0;
  }
  // .MuiOutlinedInput-input{
  //   text-align: center;
  //   padding: 0;
  // }
}

// Read more option css
.MuiSpeedDial-directionDown,
.MuiSpeedDial-directionRight {
  top: 5px !important;
  left: -15px !important;
}
.MuiSvgIcon-root {
  width: 21px !important;
  height: 21px !important;
}
.MuiSpeedDial-fab {
  width: 34px !important;
  height: 32px !important;
  background-color:var(--bg-Table-Disable) !important;
  border-radius: var(--border-radius4);
  color: #202020 !important;
  box-shadow: none !important;
}
.addRow-dataMap {
  padding-bottom: 5px !important;
}
.mapTool-Wrap {
  position: relative;
}
.MapToolSpeedAction {
  width: 70px;
  height: 40px;
  border-radius: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}
.p-4px {
  padding: 4px !important;
}
.mapToolBar-serach {
  padding-left: 2px;
  height: 100%;
  .serchIcon {
    color: #ececec;
  }
}
.pageNo-wrap {
  padding: 0px 10px;
}

// MapDataInput Scss
.mapEditInput-wrap {
  width: 221px;
  .MapEditInput-text {
    background-color:var(--bg-Table-Disable);
    border-radius: var(--border-radius10);
    border: none;
  }
  .checkIconwrap {
    margin: -42px;
    margin-top: -26px;
    background-color: var(--borderLightGrey);
    border-radius: var(--border-radius6);
    height: 33px;
    padding: 6px;
  }
}
// Css for MapInputControl component
.mapInputControl-wrap {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
  .btn-add {
    padding: 6px 36px;
  }
}
