@import "../../../css/utility.scss";
.paddingBox {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 3px;
  .MuiGrid-root {
    .MuiGrid-item {
      padding-left: 5px !important;
    }
  }
  .premap-pdf-head {
    display: flex;
    justify-content: space-between;
  }
}
.premap-data-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
  @include premap-data-container;
}
.arrow-angle-grid {
  display: flex !important;
  padding: 5px 0px !important;
  justify-content: space-evenly !important;
  .arrow-angle-page-text {
    margin: 0;
    font-size: 10px;
    color: #202020;
    font-family: var(--font-family);
  }
  .arrow-angle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .arrow-angle-condition{
      // padding: 4px;
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none !important;
    }
    .arrow-angle-left {
      height: 20px;
      color: #e72626;
    }
    .arrow-angle-right {
      height: 20px;
      color: #ececec;
    }
  }
}
.premap-loader-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  height: 768px;
  .premap-loader-style {
    padding-left: 15px;
    padding-top: 10px;
  }
}
.container-1 {
  @include mapDataTableWrap;
  border: 1px solid var(--borderLightGrey);
  height: calc(100vh - 151) !important;
  border-radius: 4px 4px 0px 0px;
  .MuiSpeedDial-actions {
    padding-top: 38px !important;
  }
  .view_pdf {
    height: calc(100vh - 199px);
    overflow: scroll;
  }
}
.container-2 {
  background: var(--bg-white);
  border: 1px solid var(--borderLightGrey);
  height: calc(100vh - 78px) !important;
  border-radius: 4px 4px 0px 0px;
  .MuiSpeedDial-actions {
    padding-top: 38px !important;
  }
  .view_pdf {
    height: calc(100vh - 130px);
    overflow: scroll;
  }
}
.table-grid {
  height: 55% !important;
  padding: 0;
}

.slide-button {
  .MuiButton-startIcon {
    margin-right: 5px;
  }
}
.dialog-style {
  display: flex;
  justify-content: space-between !important;
  padding: 0px 10px !important;
  // justify-content: space-between !important;
  .slide-button {
    button {
      margin-top: 4px !important;
      margin-bottom: 2px !important;
      @include button-black;
      height: 34px;
    }
  }
}
.fullscreen-table-wrap {
  padding: 0 8px 0 !important;
}
.ac-processor-pdf-slider-body {
  padding: 5px 0px;
  // background-color: #f3f3f3;
  .image-item {
    width: 75px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    background-color:var(--bg-Table-Disable) !important;
    border-radius: var(--border-radius4);
  }
  .react-multi-carousel-track {
    display: flex;
    align-items: flex-end !important;
  }

  .slick-prev {
    left: 10px;
    font-size: 0;
    line-height: 0;
    background: none !important;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
  }
  .slick-prev:before {
    content: "\e824";
    font-size: 20px;
    color: #002650;
    display: block;
    font-family: revicons;
    text-align: center;
    position: relative;
    font-size: 23px;
    font-weight: var(--font-bold); 
  }
  .slick-next:before {
    content: "\e825";
    font-size: 20px;
    color: #002650;
    display: block;
    font-family: revicons;
    text-align: center;
    position: relative;
    font-size: 23px;
    font-weight: var(--font-bold);
  }
  .slick-next {
    font-size: 0;
    line-height: 0;
    right: 10px;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: none !important;
  }

  .ac-processor-slider-content {
    display: flex;
    flex-direction: row !important;
    padding: 10px 0;
    width: 100%;
    border-radius: var(--border-radius4);
    div {
      display: flex;
    }
    @include flex-center-align-content();
    flex-direction: row;
    cursor: pointer;
    .label {
      margin-left: 5px !important;
      font-weight: var(--font-bold);
      font-size: 10px;
    }
    .react-multiple-carousel__arrow--left {
      left: 0 !important;
    }
    svg {
      color: #202020 !important;
      background-color: #202020 !important;
    }
    &.selectedPDF {
      background-color: #ffdbe8;
      .label {
        color: #fd3458;
      }
    }
  }
  .pdf-slider-carousel-item {
    .react-multiple-carousel__arrow::before {
      color: #002650 !important;
    }
    .react-multiple-carousel__arrow {
      background-color: inherit !important;
    }
  }
}
// @media screen and (min-width: 1600px) {
//   .container-1 {
//     height: calc(100vh - 234px);
//   }
// }
.fullview-close {
  // position: absolute!important;
  // top:10px!important;
  height: 30px;
}
