@import "../../../css/utility.scss";
.reviewbatch-main-grid {
  display: flex!important;
  flex-direction: column!important;
  .reviewbatch-main-box {
    // flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--bg-DayCard);
    height: 60;
    border-bottom: 1px solid var(--borderLightGrey);
    border-top: 1px solid var(--borderLightGrey);
    .arrow-icon-button {
      margin-left: 16px;
      font-style: normal;
      font-weight: var(--font-bold);
      font-size: 16px;
    }
  }
  .reviewbatch-table-box {
    margin-top: 16px;
    padding: 15px;
    height: calc(100vh - 192px);
  }
}
.previewop-file-grid {
  display: flex;
  flex-direction: column;
  .previewop-main-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--bg-DayCard);
    height: 50;
    p {
      margin-left: 16px;
      font-style: normal;
      font-weight: var(--font-bold); 
      font-size: 16px;
    }
    .arrow-icon-button {
      margin-left: 16px;
      font-style: normal;
      font-weight: var(--font-bold);
      font-size: 16px;
    }
  }
  .previewop-table-box {
    margin-top: 16px;
    padding: 15px;
  }
}
