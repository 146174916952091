@import "../../css/utility.scss";
.ac-pre-processor-stepper-container {
  background-color: #f6f6f6;
  .ac-pre-processor-stepper-container-body {
    border-radius: var(--border-radius6);
    background-color:var(--bg-white);
    margin: 29px 40px 32px;
    @include flex-center-align-content();
    align-items: flex-start;
    overflow: hidden;
  }
  .ac-pre-processor-stepper__content {
    @include flex-column-center();
    width: 100%;
    // width: 100% !important;
    .MuiStepConnector-alternativeLabel {
      left: calc(-50% + 14px);
      right: calc(50% + 10px);
      top: 12px;
    }
    .MuiStepConnector-root.Mui-completed {
      border-color: #00b2ff !important;
    }
    .MuiStep-completed {
      background-color: var(--bg-white);
      .MuiStepConnector-alternativeLabel {
        left: calc(-50% + 14px);
        right: calc(50% + 14px);
        top: 10px;
      }
      .svg {
        color: #00bb26 !important;
      }
      .MuiSvgIcon-root {
        border: 2px solid green !important;
        border-radius: 50% !important;
        height: 32px !important;
        width: 32px !important;
        justify-content: center !important;
      }
    }
    .MuiStepIcon-root.MuiStepIcon-active {
      color: var(--colorTextWhite);
      border: 2px solid #00b2ff !important;
      border-radius: 50%;
    }
    .MuiStepIcon-completed {
      font-size: 2rem;
      color: #00bb26;
    }
    .MuiStepIcon-root {
      color:var(--colorTextWhite);
      display: block;
      border: 2px solid #e0e0e0;
      border-radius: 50%;
      .Mui-completed {
        color: var(--color-black)!important;
      }
    }
    .MuiStepLabel-root {
      flex-direction: column;
    }
    .MuiStepConnector-line {
      border-color: #00bb26 !important;
      border-top-width: 0px !important;
    }
    .MuiStepIcon-completed {
      border-color: #00bb26;
      color: var(--colorTextWhite);
    }
    .MuiStepConnector-lineHorizontal {
      opacity: unset !important;
    }
    .MuiStep-alternativeLabel {
      width: 208px;
    }
    .MuiStepper-root {
      padding: 28px 24px;
      width: 100%;
      display: flex;

      // align-items: center;
    }
    .MuiTimelineDot-root {
      padding: 10px;
    }
    .MuiStepConnector-line {
      border-color: #979797;
    }
    .MuiStepIcon-completed {
      color: #00bb26;
    }
    .MuiStepConnector-lineHorizontal {
      border-top-width: 0px;
      opacity: 0.23;
    }
    .MuiTimelineDot-outlinedPrimary {
      border-color: #00b2ff;
    }
  }
  .ac-border-bottom {
    border-bottom: 2px solid #979797;
    opacity: 0.1;
    width: 100%;
  }
}
.stepper-wrap {
  @include stepper-wrap;
  padding: 10px 25px;
  .Mui-disabled {
    opacity: 1 !important;
  }
  .MuiStepIcon-text {
    display: none !important;
  }
  .MuiStepIcon-root {
    font-weight: var(--font-bold) !important;
    &.Mui-completed {
      color: rgba(0, 0, 0, 0.7) !important;
    }
    &.Mui-active {
      color: rgba(0, 0, 0, 0.7) !important;
    }
  }
}
.root-MuiStepIcon-root.Mui-completed {
  color: var(--main-theme-color) !important;
}
.MuiStepConnector-root {
  .Mui-disabled {
    border-top: 0 !important;
    background: #b9b9b9 !important;
  }
}
.MuiStep-root {
  padding-left: 0px !important;
}

// Stepper Label Css

.MuiStepLabel-label {
  font-weight: var(--font-bold) !important;
  opacity: unset !important;
  @media screen and (min-width: 1600px) {
    // padding: 21px 22px;
    font-size: 16px !important;
  } 
  &.Mui-active {
    color: rgba(0, 0, 0, 0.87);
  }
  &.Mui-disabled {
    color: #b9b9b9 !important;
  }
  &.Mui-completed {
    color:var(--color-black) !important;
  }
}
// End Stepper Label Css
.main-stepper{
  justify-content: center!important;
}
.stepper-wrap-label{
  margin-top: 0!important;
}
