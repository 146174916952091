@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600");
@import url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");

:root {
  --color-body: #fff;
  --secondary-body-color: #f8fbff; // bckground color for Project header, pagination, Action Bar , stepper wrap, pagination, Dashbord table background for pre, pro , vali
  --body-text-color: #000;

  // --color-body:#000;
  // --secondary-body-color:#000;
  // --body-text-color: #fff;

  // font weight
  --font-bolder: 700;
  --font-bold: 600;
  --font-normal: 500;
  --font-light: 400;

  // color variables
  --color-black: #000;
  --color-gray: #b9b9b9;
  --color-error: #fd3458;
  --colorTextWhite: #fff !important;
  --colorButtonBlack: rgba(0, 0, 0, 0.7) !important;
  --color-userPermision-label:#2d2d2d;

  // Border Radius
  --border-radius4: 4px !important;
  --border-radius6: 6px !important;
  --border-radius0: 0px !important;
  --border-radius1: 1px !important;
  --border-radius10: 10px !important;

  // Border Color
  --borderLightGrey: #b9b9b9;

  // theme color
  --main-theme-color: #1fc8cc;
  --theme-color: #4096fc;
  --sideBar-link-color: #2776d2;
  --sideBar-active-color: #fff;
  

  // background varibles
  --bg-white: #fff;
  --bg-gray: #b9b9b9;
  --bg-black: #000;
  --bg-Table-Disable: #ececec; // background for table disable pagination
  --dashboard-header: #e8e7e7;
  --bg-DayCard: #f5f5f5 !important;
  --bg-header-footer:#fff;
  // --bg-administrator-table: #f8fbff;

  // Border color
  --border: 1px solid #b9b9b9;
  --bordertableData: #ececec; // border for Table data free form , PDF

  // font size
  --font-size: 16px;

  // font family
  --font-family: "Montserrat";
}

html,
body,
#root {
  color: var(--body-text-color);
  font-size: var(--font-size);
  font-family: var(--font-family) !important;
  background-color: var(--color-body);
  height: 100%;
  width: 100%;
  font-weight: var(--font-light);
  position: relative;
  line-height: 1.5;
}
.MuiTableCell-root{
  font-family: var(--font-family) !important;
}
// Border
@mixin borderLightGrey-1 {
  // border:1px solid var(--borderLightGrey);
}

// SCss For Buttons
// scss for button gray or button disable
.Mui-disabled {
  cursor: not-allowed !important;
  color: var(--bg-gray) !important;
  &::before {
    border-bottom-style: hidden !important;
  }
  &.MuiButton-root {
    background-color: var(--bg-gray) !important;
    color: #fff!important;
  }
}

// Scss for button with background blue
@mixin button-bg-blue {
  background-color: var(--theme-color) !important;
  color: #fff !important;
  &:hover,
  &:active {
    background-color: var(--theme-color);
    color: #fff;
  }
}

// Scss for button with outline blue
@mixin outline-blue-button {
  border: 2px solid var(--theme-color);
  color: var(--theme-color);
}

// Scss for button with background black
@mixin button-black {
  background-color: var(--colorButtonBlack)!important;
  color: #fff;
}

// Scss for button with outline black
@mixin button-outline-black {
  color: var(--colorButtonBlack);
  border: 1px solid var(--colorButtonBlack);
}

// End  Css For Buttons

// Scss for login page
@mixin logPageBackground {
  background: #0037b2;
}
@mixin loginForm {
  background-color: var(--bg-white);
  .forgetPassword-disabled {
    background-color: transparent !important;
  }
  .signbtn {
    background-color: #0037b2 !important;
  }

  label {
    color: #828282;
  }
}

@mixin header {
  // Header Scss
  .MuiToolbar-root {
    background-color: var(--bg-header-footer);
  }

  .MuiTypography-subtitle1,
  .user-profile-icon {
    color: var(--body-text-color);
  }
}

@mixin Footer {
  // Footer Scss
  background-color: var(--bg-header-footer);
}

// Stepper Scss
@mixin stepper-wrap {
  background-color: var(--secondary-body-color);
}

// Action bar Scss
@mixin action-bar {
  background-color: var(--secondary-body-color);
}

@mixin project-header {
  background-color: var(--secondary-body-color);
}

@mixin premap-data-container{
  background-color:var(--Secondary-body-color);
  .MuiTypography-root.project-batch-header{
    font-weight:var(--font-bolder);
    color: var(--theme-color)!important;
  }
}
// Preprocessor, Processor Validator Dashboard Scss
@mixin dashboard-wrap {
  background-color: var(--secondary-body-color);
}

// Datepiker
@mixin BasicDatePiker-wrap {
  .box,
  .date-picker {
    background-color: var(--bg-white);
  }
}

// Upload Batch record
@mixin outerPDfWrapper {
  background-color: var(--bg-DayCard);
  .MuiTypography-root,.MuiOutlinedInput-input {
    color: #000;
  }
}

// Admin SideBar Theme
@mixin side-bar-theme-color {
  background-color: var(--theme-color) !important;
}

@mixin side-bar-link-color {
  background-color: var(--sideBar-link-color) !important;
}

@mixin side-bar-active-link-color {
  background-color: var(--sideBar-active-color) !important;
}
@mixin admin-reject-submit-btn {
  background-color: var(--theme-color) !important;
}

@mixin mapDataTableWrap {
  background-color: var(--color-body);
}

// Modal Scss
@mixin modal {
  background-color: var(--bg-white);
}

@mixin batch-processing-form{
  label{
    color:var(--color-userPermision-label);
  }
}
// flex box
@mixin felx() {
  display: flex;
  justify-content: center;
}

@mixin flex-align-start-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
}

@mixin flex-center-align-content {
  @include felx();
  align-items: center;
}

@mixin flex-row-align-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-flow: wrap;
}

@mixin flex-row-align-baseline {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

@mixin flex-column-center {
  flex-direction: column;
  @include flex-center-align-content();
}

// center classes
.h-center {
  display: flex;
  justify-content: center;
}

.input-wrap {
  margin-bottom: 16px;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px;
}

.checkboxicon {
  display: inline-block;
  height: 18px;
  width: 18px;
  border: 1px solid var(--borderLightGrey);
  border-radius: 3px;
}

.MuiCard-root {
  box-shadow: none !important;
}

.MuiButton-root,
.MuiOutlinedInput-root {
  border-radius: var(--border-radius6) !important;
  box-shadow: none !important;
  // border: none !important;
}
.MuiOutlinedInput-root {
  border: 1px solid var(--borderLightGrey);
  color: var(--body-text-color) !important;
}
.MuiMenuItem-root {
  font-size: 14px !important;
  &:hover {
    background-color: var(--theme-color) !important;
  }
}

.MuiTypography-root,
.MuiStepLabel-label {
  font-family: var(--font-family) !important;
}

.w-100 {
  width: 100% !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.positionRelative {
  position: relative !important;
}

#alert-dialog-slide-description {
  overflow-wrap: break-word !important;
}

.padd-5 {
  padding: 5px !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.float-right {
  float: right;
}

.pagination-wrap {
  display: flex;
  justify-content: center;
  padding: 7px;

  &.pagination-bg {
    background: var(--secondary-body-color);
  }
}

.wordBreak {
  word-break: break-word;
  word-break: break-all;
}
.MuiInput-input {
  color: var(--body-text-color) !important;
}
.MuiMenuItem-root:hover {
  color: #fff;
  background-color: var(--theme-color) !important;
}
