@import "../../../css/utility.scss";
// css for Reject Modal
.reject-modal {
  padding: 0px;
  .alert-dialog-slide-title {
    padding: 15px 0 0 24px !important;
    .alert-dialog-appbar {
      position: relative;
      box-shadow: none;
      background-color: var(--bg-white);
      padding: 0 !important;
      .alert-dialog-modalTitle-wrap {
        display: flex;
        flex-direction: row;
        .processor-close {
          position: inherit;
          margin: 0 10px;
          color: #9e9e9e;
        }
      }
    }
  }
  .modal-style {
    position: absolute;
    top: 12%;
    left: 30%;
    transform: translate(-50%, -50%);
    background-color: var(--bg-white);
    border-radius:var(--border-radius4);
    padding: 35px;
    display: flex;
    flex-direction: column;
    // screen size more than 1200
    @media screen and (min-width: 1200px) {
      min-width: 500px;
    }
  }
  .modalTitle {
    color: #0b3548;
    font-size: 20px;
    font-weight:var(--font-normal);
    // width:calc(100% - 10%),
  }
  .comment {
    font-weight:var(--font-normal);
    color: #0b3548;
    margin: 0px;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: 0.0075em;
  }
  .comment-box {
    border: 1px solid #7c7c7c;
    width: 100% !important;
    padding: 15px;
    &:focus-visible {
      // color: RED;
      border: 1px solid #7c7c7c !important;
      // background-color: red
    }
  }
  // css for modal buttons
  .btn-wrap {
    display: flex;
    justify-content: space-between;
    margin: 1px -9px;
    float: right;
  }
}
.processor-dailogContent {
  padding: 10px 24px !important;
  .reject-btn-wrap {
    float: right;
  }
}
