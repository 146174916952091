@import "../../../css/utility.scss";
.MuiTypography-root,
.MuiStepLabel-label {
  font-family: var(--font-family) !important;
}
.uploadpdf-main-container {
  padding: 30px;
  .tag-style {
    margin: 10px;
  }
  .uploadpdf-card-container {
    overflow-y: scroll;
    height: calc(100vh - 270px);
    padding-left: 10px;
    padding-right: 10px;
  }
}
