@import "../../css/utility.scss";
.sidebar-btn-grp {
  position: absolute;
  width: 250px;
  height: 5px;
  left: 40px;
  top: 110px;
  background: #2776d2;
  border-radius: var(--border-radius10);
}
.Sidebar-add {
  @include side-bar-theme-color ;
  width: 100%;
  height: calc(100vh - 50px) !important;
  left: 0px;
  top: 70px;
  padding: 40px;

  .btn-sidebar,
  .btn-sidebar:hover {
    @include side-bar-link-color;
    // background-color: #2776d2;
    color: white;
    border-radius: var(--border-radius10);
    margin-bottom: 10px;
    justify-content: flex-start;
    padding: 8px 16px;
  }

  .btn-sidebar-active,
  .btn-sidebar-active:hover {
    @include side-bar-active-link-color;
    // color: white;
    border-radius: var(--border-radius10);
    margin-bottom: 10px;
    justify-content: flex-start;
    padding: 8px 16px;
  }
  .btn-sidebar-selected {
    background-color: white;
    color: #2776d2;
    border-radius: var(--border-radius10);
    margin-bottom: 10px;
    justify-content: flex-start;
    padding: 8px 16px;
  }
}

.sidebar-btn {
  margin-bottom: 1px;
}

.sidebar-link {
  text-decoration: none;
}
