@import "../css/utility.scss";
.adminDatepiker{
.react-datepicker-wrapper {
  border: 1px solid #B9B9B9 !important;
  border-radius:var(--border-radius6);
  text-align: center;
  .react-datepicker__input-container {
    .DateRangePickerAdministrator {
      border: 0;

    }

    input {
      text-align: center;
      height: 40px;
      width: calc(100% - 30px);
      &:focus-visible{
        outline: none!important;
      }

    }
    .react-datepicker__close-icon::after{
        background-color: transparent!important;
        font-size: 24px!important;
        color: #000!important;
    }
  }
}
}