@import "../../../css/utility.scss";

.container-1 {
  @include mapDataTableWrap;
  border: 1px solid var(--borderLightGrey);
  height: calc(100vh - 151px);
  border-radius: 4px 4px 0px 0px;

  .MuiSpeedDial-actions {
    padding-top: 38px !important;
  }

  .view_pdf_new,
  .view_pdf {
    height: calc(100vh - 199px);
    overflow: scroll;
  }
}

.container-1,
.container-2 {
  .table-grid {
    //  overflow: scroll;
    height: 100%;
  }
}

.fullscreen-view {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.fullview-icon {
  display: flex;
  position: absolute;
  right: 35px;
  color: var(--color-black);
  top: -1px;
}

.slide-button {
  // padding-left: 20px;
  .MuiButton-startIcon {
    margin-right: 5px;
  }

  button {
    margin-top: 4px !important;
    margin-bottom: 2px !important;
    @include button-black;
  }
}

.dialog-style {
  display: flex;
  justify-content: space-between !important;
  padding: 0px 10px !important;

  // justify-content: space-between !important;
  .slide-button {
    button {
      margin-top: 4px !important;
      margin-bottom: 2px !important;
      @include button-black;
      height: 34px;
    }
  }
}

.ac-processor-pdf-slider-body {
  padding: 5px 0px;

  // background-color: #F3F3F3;
  .image-item {
    width: 75px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-multi-carousel-track {
    display: flex;
    align-items: flex-end !important;
  }

  .slick-prev {
    left: 10px;
    font-size: 0;
    line-height: 0;
    background: none !important;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
  }

  .slick-prev:before {
    content: "\e824";
    font-size: 20px;
    color: #002650;
    display: block;
    font-family: revicons;
    text-align: center;
    position: relative;
    font-size: 23px;
    font-weight: var(--font-bold); 
  }

  .slick-next:before {
    content: "\e825";
    font-size: 20px;
    color: #002650;
    display: block;
    font-family: revicons;
    text-align: center;
    position: relative;
    font-size: 23px;
    font-weight: var(--font-bold);
  }

  .slick-next {
    font-size: 0;
    line-height: 0;
    right: 10px;
    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: none !important;
  }

  .ac-processor-slider-content {
    display: flex;
    flex-direction: row !important;
    padding: 10px 0;
    @include flex-center-align-content();
    flex-direction: row;
    cursor: pointer;

    .label {
      margin-left: 5px !important;
    }

    .react-multiple-carousel__arrow--left {
      left: 0 !important;
    }
  }

  .pdf-slider-carousel-item {
    .react-multiple-carousel__arrow::before {
      color: #002650 !important;
    }

    .react-multiple-carousel__arrow {
      background-color: inherit !important;
    }
  }
}

.fullview-close {
  height: 30px;
  position: inherit;
  margin: 0 10px;
  color: #9e9e9e;
}

.data-entry-container {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 3px;

  .MuiGrid-root {
    .MuiGrid-item {
      padding-left: 5px !important;
    }
  }
}