@import "../../css/utility.scss";
.root {
  background-color: #cccccc;
}
.admin-box {
  background-color: var(--bg-DayCard);
  display: flex;
}
.vertical {
  border-left: 2px solid var(--bordertableData);
  height: 2.34em;
  margin: 1px 13px;
}
.logo {
  height: 28px;
  width: 125px;
  cursor: pointer;
}
.toolBar {
  background-color:var(--bg-white);
  box-shadow: none;
}

.admin-header {
  margin-left: auto;
  text-decoration: none;
  padding-right: 25px;
  // position: end;
  button {
    @include button-bg-blue;
    margin: 0 !important;
    height: 38px;
    min-width: 170px;
  }
}
.export-btn {
  @include button-bg-blue;
  padding: 6.76px 30px;
  margin: 0px;
  box-shadow: none;
  border-radius: var(--border-radius4);
  .MuiButton-root {
    padding: 7px 39.9px;
  }
}

.header {
  @include header;
  .MuiToolbar-root {
    min-height: 50px;
  }
  .MuiTypography-subtitle1 {
    font-weight: var(--font-normal);
    margin: 0 8px;
    padding: 1px 4px;
    font-size: 16px !important;
    line-height: 18px !important;

    @media screen and (min-width: 1600px) {
      font-size: 18px !important;
      margin: 18px 8px !important;
    }
  } 
  .user-profile-icon {
    margin-left: "5px";
  }
}
.HeaderProjectName{
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}