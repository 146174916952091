@import "../../css/utility.scss";
.dashboard-wrap {
  padding: 0 16px;
  border: 0;
 @include dashboard-wrap;
}
.hour-glass-anime {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.dashBoardTh {
  box-shadow: none;
  font-size: 14px;
  font-weight: var(--font-bold); 
  color: #202020;
  padding: 0 5px 5px;
  align-items: center;
  border: 0;
  height: calc(100vh - 130px);
  overflow-y: scroll;
  // text-align: center;
  text-align: left;
  table {
    color: "#2D2D2D";
    border-spacing: 0 10px !important;
    border: 0;
    border-collapse: separate !important;
    thead {
      th {
        background-color: var(--dashboard-header);
        font-weight: var(--font-bolder) !important;
        // text-align: center;
        text-align: left;
        padding: 4.5px 5px !important;
        &:first-child {
          width: 50px;
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
    tr {
      td {
        // text-transform: capitalize;
        font-weight:var(--font-normal);
        padding: 6px 5px !important;
        height: 58px !important;
        // text-align: center;
        text-align: left!important;
        &:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .MuiSvgIcon-root {
            order: 2 !important;
            padding-left: 2px !important;
            margin-left: 10px;
          }
        }
      }
      // th{
      //     border-bottom:0!important ;
      //     padding: 5px;
      // }
      background-color: var(--bg-white) !important;
      height: 50px;
      &.hidden-row {
        padding: 5px;
        background: var(--bg-DayCard);
        align-items: center;
        text-align: center;
        width: 100% !important;
        border-bottom: 1px solid var(--borderLightGrey);
        margin: 0 !important;
        text-transform: capitalize;
        &:first-child {
          margin-top: -5px !important;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
.row-wrap {
  text-align: center;
  text-transform: capitalize;
  .drugType-wrap {
    align-items: center;
    padding-top: 2px;
  }
  .drugType {
    height: 18px;
    .MuiChip-label {
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 12px;
    }
  }
}
.action-list {
  .MuiPopover-pape {
    box-shadow: none !important;
  }
  .MuiMenu-list {
    background-color: var(--bg-Table-Disable);
    padding: 0;

    .action-item {
      color: var(--color-black);
      font-size: 12px !important;
      padding: 8px 14px;
      border-bottom: 1px solid var(--borderLightGrey);
      svg {
        margin-right: 8px;
      }
      &:hover {
        @include button-bg-blue;
      }
    }
  }
}
.img {
  height: 21px;
  width: 17px;
}
.table-header-title:nth-child(7) {
  width: 150px;
}
@media screen and (min-width: 1600px) {
  .dashBoardTh {
    height: calc(100vh - 163px) !important;
  }
}
.new-icon {
  color: #1239ff;
  width: 30px;
  height: 30px;
  padding-left: 5px;
}
.new-icon-batch {
  font-size: large;
  color: #1239ff;
}
.priority-reject-icon {
  padding-left: 5px;
}
.assigned-status {
  text-align: center;
  width: 150px;
}
.p-name {
  display: flex;
  flex-direction: column;
}
