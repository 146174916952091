@import "../../../css/utility.scss";

.Sidebar {
  position: absolute;
  width: 330px;
  height: 710px;
  left: 0px;
  top: 70px;
  background-color: var(--theme-color);
}

.group-table,
.group-table td,
.group-table th {
  border-collapse: separate !important;
  border-spacing: 8px !important;
  border: 0px;
}

.group-table tr {
  outline: 1px solid var(--bordertableData) !important;
  border-radius: var(--border-radius4);
}

.group-table {
  width: 99% !important;
  .group-table-th {
    tr {
      outline: 1px solid #b9b9b9 !important;
    }
    .group-table-row{
      &:last-child td, &:last-child th{
        border: 0!important;
      }
    }
  }
}
.listPage-graybox{
  // background-color: GrayText;
}
.listPage-container{
  overflow-y: scroll;
  height: calc(100vh - 150px);
  box-shadow: none;
}
.listTabbleRow{
  border:0;
}