@import "../../../css/utility.scss";
.upper-container {
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid#b9b9b9;
  .save-btn {
    @include button-bg-blue;
    padding: 6.76px 30px;
    margin-right: 8px;
  }
  .form-style{
    display: block;
  }
  .upper-container-input {
    margin-right: 8px;
  }
  .upper-container-btn{
    display: flex;
    justify-content: end;
  //  padding-right: 12px;
   .save-btn {
       @include button-bg-blue;
       padding: 6.76px 30px;
     }
 }
}
.mid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  height: 63vh;
  overflow-y: scroll;
  .mid-box {
    display: flex;
    justify-content: center;
    width: 100%;
    .input-text {
      margin: 12px 8px;
    }
  }
    .input-select {
       width: 22%;
      margin: 12px 9px;
      padding: 2px;
    }
  
}
.lower-container {
  padding: 10px 20px;
  display: flex;
  justify-content: space-around;
  .lower-container-item {
    text-align: center;
    padding-left: -4px;
  }
}
.save-btn {
  @include button-bg-blue;
  padding: 6.76px 30px !important;
}
