@import "../../../css/utility.scss";
.selectDataReport {
  margin: 10px !important;
  min-width:100% !important;
  // background-color: var(--main-theme-color);
  border-radius: 10px !important;
  // margin-top:20px!important;
  .MuiOutlinedInput-input {
    padding: 9.5px 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 2;
  }
  .select-label {
    color: rgb(250, 246, 246);
    margin-top: -7px;
    z-index: 999;
  }
}
.data-deletion-wrap {
  .input-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    .selectDataReport-wrap{
      padding-right: 0px;
      text-align: enf;
    }
  }
  .MuiInputLabel-root {
    opacity: 0;
  }
  .download-pdf{
    text-align: end;
    .download-pdf_-btn{
      @include outline-blue-button;
      text-transform: capitalize;
    }
  }
}
.MuiPopover-paper {
  max-height: calc(100% - 250px) !important;
}
.data-deletion-document {
  padding: "10px";
  display: flex;
  border: 1px solid #e3c9c9;
  justify-content: center;
  width: 100%;
  overflow: scroll;
  height: 63vh;
  margin-top: 15px;
}
