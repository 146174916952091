@import "../../../css/utility.scss";

.Sidebar {
  position: absolute;
  width: 330px;
  height: 710px;
  left: 0px;
  top: 70px;
  background-color: var(--theme-color);
}
.added-list {
  background-color: var(--bordertableData);
  margin: 5px;
  border-radius: var(--border-radius6);
}

.scroller {
  overflow-y: scroll;
  height: 69vh !important;
  margin-top: 2vh !important;
  margin-bottom: 0 !important;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
  .scroller-container {
    margin-top: 2%;
  }
}

.nested-folder {
  margin-left: 47px;
}

.notselected {
  display: flex;
  cursor: pointer;
}

.selected {
  display: flex;
  cursor: pointer;
  background-color: var(--bg-DayCard);
  border-radius: var(--border-radius6);
  padding: 5px 10px;
  min-width: 200px;
}

.card-div {
  padding: 0.13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-div-unselected {
  padding: 0.13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--theme-color);
}

.header-select {
  .MuiOutlinedInput-input {
    padding: 7.5px 14px;
  }

  .ProductTypeList-wrap {
    min-width: 200px;

    .ProductTypeList {
      margin-top: -9px;
    }
  }
}

.add-btn {
  @include button-bg-blue;
  padding: 6px 40px !important;
  margin: 0 15px !important;
  height: 38px;
  text-transform: none!important;
}

.btn-continue {
  padding: 6px 52px !important;
  margin: 0 !important;
  margin-left: auto !important;
  @include button-bg-blue;
}

.list_directory-container {
  // padding: 27px;
  overflow-y: scroll;
  height: 68vh;
  margin: 0 !important;
  width: 100% !important;

  .card-wrap {
    padding: 18px;
  }

  .wrapperClass {
    display: flex;
    align-items: center;

    .selected {
      p {
        margin: 4px;
      }
    }

    .formlabel {
      margin: 0;
    }

    .m-4 {
      margin: 4px;
    }
  }

  // .main-card{
  //   width:250px;
  // }
}

.list-directory {
  position: relative;

  .header-select {
    height: calc(100vh - 64px);
  }

  .Add-batch-records {
    display: flex;
    padding: 4px 25px;
    border-bottom: 1px solid var(--borderLightGrey);
  }

  .batch-product-type {
    width: 200px;
  }
}

.sub-folder {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 76px;
  min-width: 199px;
  max-width: 199px;
  background-color: var(--bg-Table-Disable);
  border-bottom: 1px solid var(--borderLightGrey);
  padding: 0 10px;
  font-size: 12px;
  line-height: 14px;
  .nestest-subFolder {
    display: flex;
    align-items: center;
  }
  .formlabel {
    margin: 0 !important;
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:first-child {
    margin-top: -5px;
  }

  .folderNTiltle-wrap {
    display: flex;
    justify-content: center;
    cursor: pointer;

    p {
      padding: 0 8px;
    }
  }
}

.folder-icon {
  color: #ffb000;
}

.m-0 {
  margin: 0 !important;
}

.merge-pdf-header {
  background-color:var(--bg-DayCard);
  display: flex;
  align-items: center;
  padding: 0 22px;
  justify-content: space-around;
}
