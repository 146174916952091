@import "../../../css/utility.scss";
.header-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--bg-DayCard);
  height: 60;
  border-bottom: 1px solid var(--borderLightGrey);
  border-top: 1px solid var(--borderLightGrey);
  .backarrow-icon-style {
    margin-left: 2;
    font-style: normal;
    font-weight: var(--font-bold); 
    font-size: 16px;
  }
  .arrow-text-style {
    margin-left: 2;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: var(--font-bold);
    font-size: 16px;
  }
}
.data-grid-container {
  margin-top: 40px;
  .inner-grid-container1 {
    margin-left: 160px;
  }
  .inner-grid-container2 {
    margin-left: 120px;
    .outline-input-style {
      margin-top: 2px;
    }
    .text-message-style {
      color: red;
    }
  }
  .inner-grid-container3 {
    margin-left: 160px;
    .form-style {
      margin: 24px;
      .radio-group-style {
        height: 150px;
      }
    }
  }
  input {
    height: 10px !important;
    border-radius: var(--border-radius4);
  }
  input:required:valid {
    border: 1px solid rgb(250, 247, 247) !important;
  }
}
.bottom-div {
  position: fixed;
  border-top: 1px solid var(--borderLightGrey);
  bottom: 0px;
  width: calc(100% - 25%);
  text-align: right;
  padding-right: 10px;
  height: 70px;
  button {
    padding: 5px 80px;
    @include button-bg-blue;
  }
}

