@import "../../css/utility.scss";
.modal-box{
  width: 600px !important;
}
.modal-popup {
  padding: 0px 10px;
  .modal-title {
    h4 {
      font-size: 24px;
      margin: 0 0 16px;
      .close-btn{
        float: right;
      }
    }
    .modal-subtext {
      color: var(--theme-color);
    }
  }
  .button-submit {
    padding: 7px 8px;
    @include button-bg-blue;
    margin-bottom: 10px;
  }
  .error {
    label {
      color: var(--color-error);
      font-size: 14px;
      margin: 0 0 5px;
      display: inline-block;
    }
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8px 14px !important;
  }
  // .MuiInputBase-root{
  .MuiOutlinedInput-input {
    padding: 8px 14px !important;
  }
  // }
}
.css-ummxem {
  border-radius: var(--border-radius10);
}
