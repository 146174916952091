@import "../../../css/utility.scss";

.header-btn {
  background-color: rgb(31, 200, 204) !important;
  margin: "10px";
  padding-right: "100px";
}

.header-btn-selected {
  background-color: var(--bg-gray) !important;
  margin: "10px";
  padding-right: "100px";
}
