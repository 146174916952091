@import "../../../css/utility.scss";

.Sidebar {
  position: absolute;
  width: 330px;
  height: 710px;
  left: 0px;
  top: 70px;
  background-color: var(--theme-color);
}

.added-list {
  background-color: var(--bg-Table-Disable);
  margin: 5px;
  border-radius: var(--border-radius6);
}

.scroller {
  overflow-y: scroll;
  height: 70vh !important;
  margin-top: 2vh !important;
  margin-bottom: 0 !important;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;

  .selected-file-margin {
    margin: 2%;
  }
}

.nested-folder {
  margin-left: 47px;
}

.notselected {
  display: flex;
  cursor: pointer;
}

.selected {
  display: flex;
  cursor: pointer;
  background-color: var(--bg-DayCard);
  border-radius: var(--border-radius6);
  padding: 5px 10px;
  min-width: 200px;
}

.card-div {
  padding: 0.13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-div-unselected {
  padding: 0.13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--theme-color);
}

.header-select {
  .MuiOutlinedInput-input {
    padding: 7.5px 14px;
  }

  .ProductTypeList-wrap {
    min-width: 200px;

    .ProductTypeList {
      margin-top: -9px;
    }
  }
}

.add-btn {
  @include button-bg-blue;
  padding: 6px 40px !important;
  margin: 0 15px !important;
  height: 38px;
  text-transform: none !important;
}

.btn-continue {
  padding: 6px 52px !important;
  margin: 0 !important;
  margin-left: auto !important;
  @include button-bg-blue;
}

.list_directory-container {
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  li {
    width: 30%
  }

  // // padding: 27px;
  // overflow-y: scroll;
  // height: 68vh;
  // margin: 0 !important;
  // width: 100% !important;
  // .card-wrap {
  //   padding: 18px;
  // }
  // .wrapperClass {
  //   display: flex;
  //   align-items: center;
  //   .selected {
  //     p {
  //       margin: 4px;
  //     }
  //   }
  //   .formlabel {
  //     margin: 0;
  //   }
  //   .m-4 {
  //     margin: 4px;
  //   }
  // }
  // // .main-card{
  // //   width:250px;
  // // }
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    outline: 1px solid #2776d2 !important;
    margin-right: 1px;
    border-radius: var(--border-radius10);
  }
}

.list-directory {
  position: relative;

  .header-select {
    height: calc(100vh - 64px);
  }

  .Add-batch-records {
    display: flex;
    padding: 10px 25px;
    border-bottom: 1px solid var(--borderLightGrey);
  }

  .batch-product-type {
    width: 200px;
  }
}

.sub-folder {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 76px;
  min-width: 199px;
  max-width: 199px;
  background-color: var(--bg-Table-Disable);
  border-bottom: 1px solid var(--borderLightGrey);
  padding: 0 10px;
  font-size: 12px;
  line-height: 14px;

  .nestest-subFolder {
    display: flex;
    align-items: center;
  }

  .formlabel {
    margin: 0 !important;
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:first-child {
    margin-top: -5px;
  }

  .folderNTiltle-wrap {
    display: flex;
    justify-content: center;
    cursor: pointer;

    p {
      padding: 0 8px;
    }
  }
}

.folder-icon {
  color: #ffb000;
}

.m-0 {
  margin: 0 !important;
}

.uploadBatchHeader-wrap {
  background-color: var(--borderLightGrey);
}