@import "../../css/utility.scss";
.outerPDf-wrapper {
  // background-color:var(--bg-DayCard);
  border: 1px solid var(--bordertableData);
  border-radius:var(--border-radius10);
  @include outerPDfWrapper;
  .Pdf-headerName {
    margin-bottom: -8px;
    .Pdf-close {
      color: #525863;
    }
  }
  .card-wrapper {
    padding: 5px 16px 0;
    .wrapper {
      .pdf-textfield {
        border-radius: var(--border-radius10);
        border: none;
      }
      .pdfCard-icon {
        margin: -42px;
        margin-top: -26px;
      }
    }
  }
}
