@import "../../css/utility.scss";

td {
  height: 30px;
}
.audit-table-style {
  th {
    background-color:var(--bg-Table-Disable);
    min-width: 250px;
  }
  td,
  th {
    font-size: 14px;
    font-weight: var(--font-bold);
    text-align: center; 
  }
}
.audit-table-disable {
  background-color:var(--bg-Table-Disable);
  color: #b9b9b9;
}
.MuiTableCell-root {
  border-bottom: 0 !important;
}
.MuiTableRow-root{
  outline: 1px solid var(--bordertableData) !important;
}
.action-wrap{
  padding: 4px 20px;
}