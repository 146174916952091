@import "../../../css/utility.scss";
.sheet-names {
  input {
    // width: 290px;
    // background-color: #1fc8cc;
    border-radius: var(--border-radius6);
    height: 40px;
    padding: 5px 30px;
    margin: 5px;
    margin-bottom: 20px;
    border: 0;
  }
  input:focus {
    @include button-bg-blue;
  }

  select:focus {
    background-color: ivory;
  }
}
.doc-pdf {
  height: 65vh;
  overflow: scroll;
}
.pagination {
  display: inline-block;
  .paginationLabel {
    padding: 10px;
  }
}
.pagination button {
  color: var(--color-black);
  float: left;
  padding: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
}
.PdfOutput-wrap {
  background-color: var(--bg-DayCard);
  height: 62px;
  .PdfOutput-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--bg-DayCard);
    height: 60px;
    border-bottom: 1px solid var(--borderLightGrey);
    border-top: 1px solid var(--borderLightGrey);
    p {
      margin-left: 2px;
      font-style: normal;
      font-weight: var(--font-bold);
      font-size: 16px;
    }
  }
} 
.xlsx-wrap {
  padding: 10px 30px 0;
  width: 100%;
  height: 70vh;
  .xlsx-fileViewer {
    height: 100vh !important;
  }
}
.react-grid-HeaderCell-sortable {
  pointer-events: none;
}

.react-grid-Grid {
  min-height: 58vh !important;
}
.react-grid-Canvas {
  height: 100% !important;
}
.pg-viewer-wrapper {
  overflow: hidden;
}
.bottom-div {
  .admin-reject-btn {
    @include admin-reject-submit-btn;
  }
  .admin-submit-btn {
    @include admin-reject-submit-btn;
  }
}
