@import "../../../css/utility.scss";

.added-list {
  background-color: var(--bg-Table-Disable);
  margin: 5px;
  border-radius: var(--border-radius6);
  padding-top: 5px;
  padding-bottom: 5px;
  .added-list-info {
    word-break: break-all;
    .MuiTypography-root {
      font-size: 14px !important;
      line-height: 16px;
    }
    img {
      width: 15px;
    }
  }
}

.text-title {
  margin-left: "1px";
  font-size: "14px";
}
.list-wrap {
  width: 90% !important;
  margin: 5% !important;
  background-color: var(--bg-white) !important;
  border: 2px solid #b9b9b9 !important;
  border-radius: 10px !important;
}
.checkboxTitleIcon {
  width: 15px;
}
.checkboxTitleIcon-title {
  margin-left: 1px;
}
