@import url(../../../css/utility.scss);
.DpCalculation{
    .MuiTypography-root{
        margin: 15px 20px;
    }
    .MuiFormControl-root{
        margin-left: 20px;
        width: calc(100% - 30px);
    }
    .DpCalculation-list{
        margin: 13px -1px;
        overflow: scroll;
        height: 62vh;
    }
    .DpCalculation-list-card{
        background-color: #ECECEC;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        margin: 11px 20px;
        .DpCalculation-names{
            margin: 16px 8px;
        }
    }
}