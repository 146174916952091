@import "../../../../css/utility.scss";
.date-time-div {
  background-color: var(--bg-black);
  width: 187px;
  color: white;
  height: 38px;
  padding: 10px;
  text-align: center;
  border-radius: var(--border-radius6);
  margin-left: 10px;
}
.date-time-div1 {
  background-color: var(--borderLightGrey);
  width: 187px;
  color: white;
  height: 38px;
  padding: 10px;
  text-align: center;
  border-radius: var(--border-radius6);
  margin-left: 10px;
}
.auditHistory-wrap {
  border: 1px solid var(--borderLightGrey);
  margin: 5px;
  border-radius: var(--border-radius6);
  height: calc(100vh - 244px);
  overflow: scroll;
  table {
    tr {
      border-bottom: 1px solid var(--borderLightGrey);
      outline: none !important;
      td {
        width: 150px;
        border-right: 1px solid var(--borderLightGrey);
        padding: 6px 16px !important;
        &:first-child {
          font-weight: var(--font-bolder); 
        }
      }
    }
  }
}
.project-title{
  margin-top:20px;
  // padding-bottom: 10px;
  // border-bottom: 2px solid #B9B9B9;
  .project-title-main{
    border-right:1px solid #202020;
    height: 32px;
    padding:5px 15px;
    .projectName{
      font-size: 12px;
      margin-top: -10px;
    }
    .projectName_1{
      font-size: 16px;
      font-weight: var(--font-bolder);
    }
  }
}
.auditHistory-outer{
  padding: 15px;
  display: flex;
}