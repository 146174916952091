@import "../../../css/utility.scss";
.search-container {
  display: flex;
  border-radius: var(--border-radius6);
  .search-form {
    .DropDownSearch-label {
      margin: -4px;
      margin-left: 3px;
      font-size: 14px;
      font-weight: var(--font-normal);
    } 
    .search-label {
      margin: -4px;
      margin-left: 3px;
    }
  }
  .MuiOutlinedInput-input {
    padding: 9.6px 25px 8.3px 10px !important;
    font-size: 14px;
  }
}
.status-search {
  .MuiOutlinedInput-input {
    padding: 6.71px 32px 8.3px 15px !important;
  }
  .StatusLable {
    margin-top: -7px;
    font-size: 14px;
  }
}
.input-drop-select {
  background-color: var(--bg-DayCard);
  border:0;
  border-radius: var(--border-radius6);
  padding: 0;
  color: var(--theme-color);
  font-size: 14px !important;
  border-right: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  // margin: 3.2px;
}
.input-select {
  background-color: var(--bg-white);
  border-radius: var(--border-radius10);
  padding: 0px;
  border: 0;
  color: var(--theme-color);
  font-size: 14px !important;
  border-right: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;
}
.search {
  position: "relative";
  border-radius: 1px;
  // background-color: var(--bg-white);
  border: 1px solid var(--bordertableData);
  border-radius: var(--border-radius6);
  display: flex;
  align-items: center;
  margin: 0 20px;
  width: 100vh;
  padding: 2px !important;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  &:hover {
    border: 1px solid black;
  }
}
.search-wrapper {
  padding: 0 2px;
  height: "100%";
  position: "absolute";
  pointer-events: "none";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  .MuiSvgIcon {
    color: #b9b9b9;
    float: right;
  }
  .input-style {
    font-size: 14px !important;
  }
  .MuiInputBase-input {
    padding: 0px 0 5px !important;
  }
}
@media screen and (min-width: 1600px) {
  .search-container,
  .search {
    border-radius: var(--border-radius10);
    padding: 4px !important;
    .MuiOutlinedInput-input {
      padding: 13.6px 32px 8.3px 15px !important;
    }
  }
  .search-wrapper {
    .MuiInputBase-input {
      padding: 4px 0 5px !important;
    }
  }
  .status-search {
    .MuiOutlinedInput-input {
      padding: 8.3px 32px 8.3px 15px !important;
    }
  }
}
